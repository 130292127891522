<template>
  <div class="container-fluid">
    <TableWithPagination
      ref="partners"
      :items="partners"
      :fields="partnersFields"
      :totalRows="partnersTotal"
      @change-params="onPartnersChangeParams"
      @row-selected="onPartnersRowSelected"
    >
      <template v-slot:filters="{ filters, changeFilter }">
        <div class="row">
          <div class="col">
            <b-form-input v-model="filters.search" placeholder="Поиск" type="search" @input="changeFilter('search', $event)"></b-form-input>
          </div>
        </div>
      </template>
      <template v-slot:filters-actions v-if="isManufacturer && manufacturer.hasCertificate">
        <b-button variant="success" @click="onAddPartner">Добавить партнера</b-button>
      </template>
      <template v-slot:row-actions v-if="isAdmin">
        <b-button class="mr-2 btn-danger" v-b-modal.modalDeletePartner>Удалить партнера</b-button>
        <b-button class="mr-2 btn-success" v-b-modal.modalChangeUser>Сменить пользователя</b-button>
        <b-button class="mr-2 btn-success" @click="onPartnerShow">Просмотр</b-button>
        <b-button class="mr-2 btn-success" @click="onEditPartner" :disabled="isEditDisabled">Редактировать</b-button>
        <span v-if="isEditDisabled" class="text-danger">Данный партнер не подтвержден и не может быть отредактирован</span>
        <span class="ml-3 spinner" v-if="loading">
          <b-spinner variant="success" label="Spinning"></b-spinner>
        </span>
        <div class="text-danger">{{ message }}</div>
      </template>
    </TableWithPagination>

    <RightSidebar
      form="PartnerForm"
      :form-data="partnerFormData"
      :mode="partnerFormMode"
      :show="partnerFormShow"
      @update:show="partnerFormShow = $event;partnerFormData = {}"
      @saved:form-data="onSavedPartnerForm"
    ></RightSidebar>

    <b-modal id="modalDeletePartner" title="Удалить партнера" ok-title="Подтвердить" cancel-title="Отмена" centered @ok="onDeletePartner">
      Подтвердите удаление партнера
    </b-modal>

    <b-modal
      v-if="isAdmin"
      id="modalChangeUser"
      title="Сменить пользователя"
      size="lg"
      ok-title="Сменить"
      cancel-title="Отмена"
      centered
      @show="onShowUsersModal"
      @hidden="onHideUsersModal"
      :ok-disabled="!isUserSelected"
      @ok="onChangeUser"
    >
      <div class="mb-4 row">
        <div class="col-6">
          <b-form-input v-model="searchUserText" placeholder="Поиск" type="search" @input="onSearchUserChange"></b-form-input>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <b-table
            ref="selectableTable"
            sticky-header="calc(100vh - 420px)"
            hover
            selectable
            select-mode="single"
            :fields="modalUsersFields"
            :items="usersByRegion"
            :no-local-sorting="true"
            @row-selected="onUsersRowSelected"
          ></b-table>
          <div v-if="!usersByRegionTotal" class="text-center">Нет записей</div>
        </div>
      </div>
    </b-modal>

    <b-modal v-if="isAdmin" id="modalUserIsLocked" title="Пользователь заблокирован" size="lg" ok-title="ДА" cancel-title="Нет" centered @ok="onChangeLockedUser">
      <p>Данный пользователь заблокирован.</p>
      <p>Вы действительно хотите разблокировать пользователя и привязать его к партнеру?</p>
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapActions, mapGetters } from "vuex";
import { TableWithPagination, RightSidebar } from "@/components"

export default {
  name: "Partners",
  components: {
    TableWithPagination,
    RightSidebar
  },
  data() {
    return {
      loading: false,
      message: null,

      filters: {
        search: ""
      },
      partnersFields: [],
      selectedPartner: null,
      partnerFormData: {},
      partnerFormMode: null,
      partnerFormShow: false,

      searchUserText: "",
      modalUsersFields: [
        { key: "firstName", label: "Имя", sortable: false },
        { key: "lastName", label: "Фамилия", sortable: false },
        { key: "email", label: "Email", sortable: false }
      ],
      modalUsersRowSelect: null,

    };
  },
  methods: {
    ...mapActions(["showErrorAlert"]),
    ...mapActions("regions", ["fetchRegions"]),
    ...mapActions("partners", [
      "fetchPartners",
      "createDealer",
      "adminEditDealer",
      "clearDealersByInn",
      "addDealerAddress",
      "fetchUsersByPartnerRegion",
      "changeActivePartnerUser",
      "fetchManufacturerPartners",
      "fetchManufacturerById",
      "deletePartner",
    ]),

    async onDeletePartner() {
      this.loading = true;
      this.deletePartner(this.selectedPartner.id).then(() => {
        this.loading = false;
        this.onSavedPartnerForm();
      });
    },

    onPartnersChangeParams(params) {
      if (this.isManufacturer && this.partnerId) {
        this.fetchManufacturerPartners({ id: this.partnerId, params });
      } else {
        this.fetchPartners(params);
      }
    },
    onPartnersRowSelected(partner) {
      this.selectedPartner = partner;
      if (!this.isAdmin) this._showSelectedPartner();
    },
    onPartnerShow() {
      this._showSelectedPartner();
    },
    _showSelectedPartner() {
      if (this.selectedPartner) this.$router.push(`/partners/${this.selectedPartner.id}`);
    },

    onAddPartner() {
      this.partnerFormData = {}
      this.partnerFormMode = "ADD"
      this.partnerFormShow = true
    },
    onEditPartner() {
      this.partnerFormMode = "EDIT"
      this.partnerFormData = { ...this.selectedPartner };
      this.partnerFormShow = true
    },

    onSavedPartnerForm() {
      this.$refs.partners.reload();
    },

    onShowUsersModal() {
      this.fetchUsersByPartnerRegion({ id: this.selectedPartner.id, search: null });
    },
    onHideUsersModal() {
      // TODO:
    },
    onSearchUserChange() {
      this.fetchUsersByPartnerRegion({ id: this.selectedPartner.id, serach: this.searchUserText });
    },
    onUsersRowSelected(items) {
      this.modalUsersRowSelect = (items || [])[0];
      console.log(this.modalUsersRowSelect);
    },
    onChangeUser() {
      if (this.modalUsersRowSelect.isLocked) {
        this.$bvModal.show("modalUserIsLocked");
      } else {
        this._changeActivePartnerUser();
      }
    },
    onChangeLockedUser() {
      this._changeActivePartnerUser();
    },
    _changeActivePartnerUser() {
      this.changeActivePartnerUser({ id: this.selectedPartner.id, userId: this.modalUsersRowSelect.id });
    },
    cancelFormChange() {
      this.dealerEditForm = {};
      this.isSidebarOpen = false;
    }
  },

  computed: {
    ...mapGetters(["userRole", "isAdmin", "isLeader", "isManager", "isCurator", "isManufacturer"]),
    ...mapState({
      partnerId: state => state.auth.user.partnerId
    }),
    ...mapGetters("regions", ["regionsDD"]),
    ...mapState("partners", {
      manufacturer: state => state.manufacturer,
      partners: state => state.partners,
      partnersTotal: state => state.partnersTotal,
      partnersByInn: state => state.partnersByInn,
      isShowDealerForm: state => state.showAddNewPartnerForm,
      isShowAddressForm: state => !state.showAddNewPartnerForm,
      isDealerExist: state => state.partnerExists,
      usersByRegion: state => state.usersByByPartnerRegion,
      usersByRegionTotal: state => (state.usersByByPartnerRegion || []).length
    }),

    isEditDisabled() {
      return this.selectedPartner.status !== 2; // Подтвержден
    },

    isUserSelected() {
      return !!this.modalUsersRowSelect;
    }
  },
  created() {
    this.fetchRegions();
    if (this.isManufacturer && this.partnerId) {
      this.fetchManufacturerById(this.partnerId);
      this.partnersFields = [
        { key: "inn", label: "ИНН", sortable: true },
        { key: "name", label: "Наименование", sortable: true },
        { key: "contactPerson", label: "Контактное лицо", sortable: true },
        { key: "contactPersonPhone", label: "Телефон", sortable: true }
      ];
    } else {
      this.partnersFields = [
        { key: "inn", label: "ИНН", sortable: true },
        { key: "name", label: "Наименование", sortable: true },
        { key: "manufacturerInn", label: "Производитель ИНН", sortable: true },
        { key: "manufacturerName", label: "Производитель Наименование", sortable: true },
        { key: "region", label: "Регион", sortable: true },
        { key: "manager", label: "Менеджер", sortable: true },
        { key: "statusView", label: "Статус", sortable: true }
      ];
    }
  }
};
</script>
